import React, { useState, useEffect } from 'react'
import { addDoc, collection, getDocs } from 'firebase/firestore'
import { db, storage } from '../config/firebaseConfig'
import '../css/dashboard.css'
import { getDownloadURL, uploadBytes, ref as storageRef } from '@firebase/storage'
import { Link } from 'react-router-dom'

function DashBoard() {

    const [testTours, setTestTours] = useState([])
    const [featuredTours, setFeaturedTours] = useState([])
    const [pastTours, setPastTours] = useState([])
    const [religiousTours, setReligiousTours] = useState([])
    const [europeTours, setEuropeTours] = useState([])
    const [customTours, setCustomTours] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [newTour, setNewTour] = useState({
        cardImage: "",
        destination: "",
        highlights: "",
        hotels: "",
        image1: "",
        image2: "",
        image3: "",
        image4: "",
        imageBanner: "",
        imageContent1: "",
        imageContent2: "",
        imageContent3: "",
        imageContent4: "",
        inclusions: "",
        introContent: "",
        itinerary: [],
        price: "",
        tourSummary: [],
        tourTime: "",
    })

    const fetchTours = async () => {
        const collectionRef = collection(db, "test");
        const tourPackageRef = collection(db, "tourPackages")
        const querySnapshot = await getDocs(collectionRef);
        const toursSnapshot = await getDocs(tourPackageRef)
        const testTours = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }))
        const tours = toursSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }))
        setTestTours(testTours);
        setFeaturedTours(tours.filter(item => item.tourTime === "featured"))
        setPastTours(tours.filter(item => item.tourTime === "past"))
        setReligiousTours(tours.filter(item => item.tourTime === "religious"))
        setEuropeTours(tours.filter(item => item.tourTime === "europe"))
    }


    useEffect(() => {
        fetchTours();
    }, [])


    const createTourPackage = async (e) => {
        e.preventDefault();
        const docRef = collection(db, "test");
        try {
            await addDoc(docRef, newTour);
            alert('New Tour Created');
            fetchTours();
        } catch (err) {
            console.error(err);
        }
    };

    const handleImageUpload = (event, fieldName) => {
        event.preventDefault();
        if (event.target.files[0]) {
            const file = event.target.files[0];
            const imageRef = storageRef(storage, `${file.name}`)

            uploadBytes(imageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    console.log(downloadURL)
                    setNewTour({ ...newTour, [fieldName]: downloadURL });
                });
            }).catch((error) => console.error("Error uploading image", error)
            )
        }
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-navigation">
                <button className="dashboard-nav-btns" onClick={() => setIsOpen(!isOpen)}>Create Tour</button>
                <form className={`${isOpen ? 'create-tour-container' : 'close'}`} onSubmit={(createTourPackage)}>
                    <input
                        type="file"
                        placeholder="cardImage"
                        onChange={(e) => handleImageUpload(e, 'cardImage')}
                    ></input>
                    <input
                        type="text"
                        placeholder="destination"
                        onChange={(e) => setNewTour({ ...newTour, destination: e.target.value })}
                    ></input>
                    <button className="dashboard-nav-btns" type="submit">Submit</button>
                    <button className="dashboard-nav-btns" type="reset">Reset</button>
                </form>
            </div>

            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="db-section-title">
                <h2>
                    Not Yet Published
                </h2>
            </div>
            <div className="edit-tours-container">
                <div className="db-tours-wrapper">
                    {
                        testTours.map(item => {
                            return <Link className="dashboard-tour-cards" to={`/tourEdit/${item.id}`}><img className="db-tour-img" alt="card-img" src={`${item.cardImage}`} key={item.id}></img> <p>{item.destination}</p></Link>
                        })
                    }
                </div>
            </div >
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="db-section-title">
                <h2>
                    Featured Tours
                </h2>
            </div>
            <div className="edit-tours-container">
                <div className="db-tours-wrapper">
                    {featuredTours.map(item => (
                        <div className="dashboard-tour-cards" key={item.id}>
                            <Link to={`/tourEdit/${item.id}`}>
                                <img className="tour-img" alt="card-img" src={item.cardImage} />
                                <p>{item.destination}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="db-section-title">
                <h2>
                    Religious Tours
                </h2>
            </div>
            <div className="edit-tours-container">
                <div className="db-tours-wrapper">
                    {religiousTours.map(item => (
                        <div className="dashboard-tour-cards" key={item.id}>
                            <Link to={`/tourEdit/${item.id}`}>
                                <img className="db-tour-img" alt="card-img" src={item.cardImage} />
                                <p>{item.destination}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="db-section-title">
                <h2>
                    Europe Tours
                </h2>
            </div>
            <div className="edit-tours-container">
                <div className="db-tours-wrapper">
                    {europeTours.map(item => (
                        <div className="dashboard-tour-cards" key={item.id}>
                            <Link to={`/tourEdit/${item.id}`}>
                                <img className="tour-img" alt="card-img" src={item.cardImage} />
                                <p>{item.destination}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="db-section-title">
                <h2>
                    Custom Tours
                </h2>
            </div>
            <div className="edit-tours-container">
                <div className="db-tours-wrapper">
                    {customTours.map(item => (
                        <div className="dashboard-tour-cards" key={item.id}>
                            <Link to={`/tourEdit/${item.id}`}>
                                <img className="db-tour-img" alt="card-img" src={item.cardImage} />
                                <p>{item.destination}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="db-section-title">
                <h2>
                    Past Tours
                </h2>
            </div>
            <div className="edit-tours-container">
                <div className="db-tours-wrapper">
                    {pastTours.map(item => (
                        <div className="dashboard-tour-cards" key={item.id}>
                            <Link to={`/tourEdit/${item.id}`}>
                                <img className="db-tour-img" alt="card-img" src={item.cardImage} />
                                <p>{item.destination}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>

        </div>
    )
}

export default DashBoard