import React from 'react'

function Banner() {

    const bannerPic = '/assets/seaOfGalilee.jpeg'
    return (
        <div>
            <div className="banner-container">
                <img className="banner-img" alt="seaOfGalilee" src={bannerPic}></img>
                <p className="banner-text">"Uncover The Divine: Embark On A Holy Land Journey"</p>
            </div>
        </div >
    )

}

export default Banner