import React, { useEffect, useState } from 'react';
import '../css/touredit.css';
import { db, storage } from '../config/firebaseConfig';
import { collection, addDoc, getDoc, doc, updateDoc, arrayUnion, arrayRemove, deleteDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom'
import { ref as storageRef, getDownloadURL, uploadBytes } from 'firebase/storage'
import BookingForm from './BookingForm';

function TourEdit({ openBookingForm, setOpenBookingForm }) {
    const { tourId } = useParams()
    const [tourPage, setTourPage] = useState({})
    const [openItinerary, setOpenItinerary] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('')
    const [newItineraryItem, setNewItineraryItem] = useState("")
    const [newTourSummaryItem, setNewTourSummaryItem] = useState("")
    const [addContent, setAddContent] = useState([])
    const [currentField, setCurrentField] = useState("")
    const [tourTime, setTourTime] = useState("")
    const [collectionName, setCollectionName] = useState("")


    useEffect(() => {
        fetchTourData()
    }, [])

    const fetchTourData = async () => {
        const testDocRef = doc(db, "test", tourId);
        const testDocSnap = await getDoc(testDocRef);
        const tourDocRef = doc(db, "tourPackages", tourId);
        const tourDocSnap = await getDoc(tourDocRef)

        if (testDocSnap.exists()) {
            setTourPage(testDocSnap.data());
            setCollectionName("test")
        } else if (tourDocSnap.exists()) {
            setTourPage(tourDocSnap.data());
            setCollectionName("tourPackages")
        } else {
            console.log("No such document!");
        }
    };

    const handleAddTourSummaryItem = async (e) => {
        e.preventDefault(); // Prevent the default form submit action

        const tourRef = doc(db, collectionName, tourId); // Reference to the Firestore document
        try {
            await updateDoc(tourRef, {
                tourSummary: arrayUnion(newTourSummaryItem) // Use arrayUnion to add the new item
            });
            console.log("New item added to tour summary");
            setNewTourSummaryItem(""); // Clear the input field after successful addition
            fetchTourData(); // Fetch the updated tour data to reflect changes
        } catch (error) {
            console.error("Error updating tour summary: ", error);
        }
    };

    const handleRemoveTourSummaryItem = async (e) => {
        e.preventDefault(); // Prevent the default form submit action

        const docRef = doc(db, collectionName, tourId); // Reference to the Firestore document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let tourSummary = docSnap.data().tourSummary;

            if (tourSummary.length > 0) {
                tourSummary.pop();
                await updateDoc(docRef, {
                    tourSummary: tourSummary
                });
                console.log("Last item removed from tour summary");
                fetchTourData();
            }
        } else {
            console.log("No such document!")
        }

    };


    const handleAddItineraryItem = async (e) => {
        e.preventDefault(); // Prevent the default form submit action

        const tourRef = doc(db, collectionName, tourId); // Reference to the Firestore document
        try {
            await updateDoc(tourRef, {
                itinerary: arrayUnion(newItineraryItem) // Use arrayUnion to add the new item
            });
            console.log("New item added to tour summary");
            setNewItineraryItem(""); // Clear the input field after successful addition
            fetchTourData(); // Fetch the updated tour data to reflect changes
        } catch (error) {
            console.error("Error updating tour summary: ", error);
        }
    };

    const handleRemoveItineraryItem = async (e) => {
        e.preventDefault(); // Prevent the default form submit action

        const docRef = doc(db, collectionName, tourId); // Reference to the Firestore document
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let itinerary = docSnap.data().itinerary;

            if (itinerary.length > 0) {
                itinerary.pop();
                await updateDoc(docRef, {
                    itinerary: itinerary
                });
                console.log("Last item removed from tour summary");
                fetchTourData();
            }
        } else {
            console.log("No such document!")
        }

    };

    const handleAddPicture = async (e, fieldName) => {
        e.preventDefault();
        const tourRef = doc(db, collectionName, tourId);
        if (e.target.files[0]) {
            const file = e.target.files[0];
            const imageRef = storageRef(storage, `${Date.now()}_${file.name} `)

            try {
                const snapshot = await uploadBytes(imageRef, file);
                const downloadURL = await getDownloadURL(snapshot.ref);
                console.log(downloadURL);

                // Update local state
                setTourPage(prevState => ({
                    ...prevState,
                    [fieldName]: downloadURL
                }));

                // Update Firestore document
                const tourRef = doc(db, collection, tourId);
                await updateDoc(tourRef, {
                    [fieldName]: downloadURL
                });

                console.log("Image uploaded and document updated");
            } catch (error) {
                console.error("Error uploading image", error);
            }
        }
    }


    const handleAddContent = async (e, [fieldName]) => {
        e.preventDefault();
        const tourRef = doc(db, collectionName, tourId)
        try {
            await updateDoc(tourRef, {
                [fieldName]: addContent
            });
            console.log("New item added to tour summary");
            setAddContent(""); // Clear the input field after successful addition
            fetchTourData(); // Fetch the updated tour data to reflect changes
        } catch (error) {
            console.error("Error updating tour summary: ", error);
        }
    }

    const handleSaveChanges = async (e) => {
        e.preventDefault();

        const tourRef = doc(db, collectionName, tourId);

        try {
            if (currentField === "highlights" || currentField === "inclusions" || currentField === "hotels" || currentField === "price") {
                // If the currentField is one of the array fields, use arrayUnion
                await updateDoc(tourRef, {
                    [currentField]: arrayUnion(addContent)
                });
            } else {
                // For other fields, update directly
                await updateDoc(tourRef, { [currentField]: addContent });
            }

            console.log("Document updated successfully");

            // Fetch the latest tour data to ensure the UI is in sync with the backend
            await fetchTourData();

            // Optionally, reset the specific field state to clear the form/UI
            setAddContent('');
        } catch (error) {
            console.error("Error updating document:", error);
        }
    }


    const handleFieldChange = (e) => {
        setCurrentField(e.target.value);
    }

    const handlePublishTour = async (e) => {
        e.preventDefault();
        const tourData = {
            ...tourPage,
            tourTime: tourTime
        };

        // Add document to "tourPackages" collection
        const tourPackagesRef = collection(db, "tourPackages");
        try {
            const docRef = await addDoc(tourPackagesRef, tourData);
            alert(`Tour moved to ${tourTime}`);

            // If document added successfully to "tourPackages", delete it from "test"
            const testDocRef = doc(db, "test", tourId); // Use the same ID as in "tourPackages"
            await deleteDoc(testDocRef);
            console.log("Document deleted from 'test' collection.");
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <form onSubmit={handlePublishTour}>
                <select onChange={(e) => setTourTime(e.target.value)} value={tourTime}>
                    <option value="Select">Select</option>
                    <option value="featured">Featured Tours</option>
                    <option value="custom">Custom Tours</option>
                    <option value="past">Past Tours</option>
                    <option value="religious">Religious Tours</option>
                    <option value="europe">Europe tours</option>
                </select>
                <button type="submit">Publish Tour</button>
            </form>
            <div className="banner-container">
                <img className="banner-img" alt="img-banner" src={`${tourPage.imageBanner}`}></img>
                <p className="banner-text"></p>
                <input
                    type="file"
                    onChange={(e) => handleAddPicture(e, 'imageBanner')}
                />
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="tour-details-container">
                <div className="lh-container">
                    <div className="tour-summary-container">
                        <div className="tour-summary">
                            {
                                tourPage.tourSummary?.map(item => {
                                    return <p key={item.id}>{item}</p>
                                })
                            }
                        </div>
                        <form onSubmit={handleAddTourSummaryItem}>
                            <input
                                type="text"
                                value={newTourSummaryItem}
                                onChange={(e) => setNewTourSummaryItem(e.target.value)}
                                placeholder="Add New Tour Summary Item"
                            />
                            <button type="submit">Add Line</button>
                            <button type="button" onClick={handleRemoveTourSummaryItem}>Remove Last Line</button>
                        </form>

                        <div className="tour-summary-btn-container"><button className="tour-summary-btn" onClick={() => setOpenBookingForm(true)}>Contact Us For Other City Departure</button></div>
                    </div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <div className="intro-container">
                        <div className="intro-btns-container">
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('highlights') }}>Highlights</button>
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('inclusions') }}>Inclusions</button>
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('hotels') }}>Hotels</button>
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('price') }}>Price</button>
                            <button className="intro-btns" onClick={() => setOpenBookingForm(true)}>Booking Form</button>
                            <div className={`${openBookingForm ? 'show-form' : 'close-form'} `}>
                                <div className="form-overlay"></div>
                                <BookingForm setOpenBookingForm={setOpenBookingForm} />
                            </div>
                        </div>
                        {showModal && (
                            <div className="modal open">
                                <div className="modal-text">
                                    <ul>
                                        {tourPage[modalContent]?.map((item, index) => (
                                            <p key={index}>{item}</p>
                                        ))}
                                    </ul>
                                </div>
                                <button className="intro-btns" onClick={() => setShowModal(false)}>Close</button>
                            </div>
                        )}
                        <select onChange={handleFieldChange} value={currentField}>
                            <option value="">Select</option>
                            <option value="highlights">Highlights</option>
                            <option value="inclusions">Inclusions</option>
                            <option value="hotels">Hotels</option>
                            <option value="price">Price</option>
                        </select>
                        <form onSubmit={handleSaveChanges}>
                            <textarea
                                type="text"
                                value={addContent}
                                onChange={(e) => setAddContent(e.target.value)}
                                placeholder="Update Introduction Content"
                            />
                            <button type="submit">Save Changes</button>
                        </form>
                        <div className="border-margin">
                            <div className="border"></div>
                        </div>
                        <div className="intro-content-container">
                            <p>Introduction</p>
                            <p>
                                {tourPage.introContent}
                            </p>
                            <form onSubmit={handleSaveChanges}>
                                <textarea
                                    type="text"
                                    value={addContent}
                                    onChange={(e) => setAddContent(e.target.value)}
                                    placeholder="Update Introduction Content"
                                />
                                <button type="submit" onClick={() => setCurrentField("introContent")}>Save Changes</button>
                            </form>
                        </div>
                    </div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <div className="itinerary-container">
                        <p onClick={() => setOpenItinerary(!openItinerary)}>Itinerary</p>
                        <div className={`itinerary-day ${openItinerary ? '' : 'close'} `}>
                            {
                                tourPage.itinerary?.map(item => {
                                    return <p key={item.id}>{item}</p>
                                })
                            }
                        </div>
                        <form onSubmit={handleAddItineraryItem}>
                            <input
                                type="text"
                                value={newItineraryItem}
                                onChange={(e) => setNewItineraryItem(e.target.value)}
                                placeholder="Add New Itinerary Item"
                            />
                            <button type="submit">Add to Itinerary</button>
                            <button type="button" onClick={handleRemoveItineraryItem}>Remove Last Line</button>
                        </form>
                    </div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <img className="tour-detail-imgs" src={`${tourPage.image1} `} alt="img1"></img>
                    <input type="file" onChange={(e) => handleAddPicture(e, 'image1')} />
                    <div className="tour-img-text">{tourPage.imageContent1}</div>
                    <form onSubmit={handleSaveChanges}>
                        <textarea
                            type="text"
                            value={addContent}
                            onChange={(e) => setAddContent(e.target.value)}
                            placeholder="Update Image 1 Content"
                        />
                        <button type="submit" onClick={() => setCurrentField("imageContent1")}>Save Changes</button>
                    </form>
                </div>
                <div className="rh-container">
                    <img className="tour-detail-imgs" src={`${tourPage.image2} `} alt="img2"></img>
                    <input type="file" onChange={(e) => handleAddPicture(e, 'image2')} />
                    <div className="tour-img-text">
                        {tourPage.imageContent2}
                    </div>
                    <form onSubmit={handleSaveChanges}>
                        <textarea
                            type="text"
                            value={addContent}
                            onChange={(e) => setAddContent(e.target.value)}
                            placeholder="Update Image 2 Content"
                        />
                        <button type="submit" onClick={() => setCurrentField("imageContent2")}>Save Changes</button>
                    </form>
                    <img className="tour-detail-imgs" src={`${tourPage.image3} `} alt="img3"></img>
                    <input type="file" onChange={(e) => handleAddPicture(e, 'image3')} />
                    <div className="tour-img-text">{tourPage.imageContent3}</div>
                    <form onSubmit={handleSaveChanges}>
                        <textarea
                            type="text"
                            value={addContent}
                            onChange={(e) => setAddContent(e.target.value)}
                            placeholder="Update Image 3 Content"
                        />
                        <button type="submit" onClick={() => setCurrentField("imageContent3")}>Save Changes</button>
                    </form>
                    <img className="tour-detail-imgs" src={`${tourPage.image4} `} alt="img4"></img>
                    <input type="file" onChange={(e) => handleAddPicture(e, 'image4')} />
                    <div className="tour-img-text">{tourPage.imageContent4}</div>
                    <form onSubmit={handleSaveChanges}>
                        <textarea
                            type="text"
                            value={addContent}
                            onChange={(e) => setAddContent(e.target.value)}
                            placeholder="Update Image 4 Content"
                        />
                        <button type="submit" onClick={() => setCurrentField("imageContent4")}>Save Changes</button>
                    </form>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
        </div >

    )
}

export default TourEdit