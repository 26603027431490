import React, { useEffect, useState } from 'react'
import '../css/featuredTours.css';
import TourCards from './TourCards';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { Link } from 'react-router-dom';
import UnderConstruction from './UnderConstruction';


function EuropeTours() {

    const articleRef = collection(db, "tourPackages")
    const [europeTours, setEuropeTours] = useState([])
    const [loading, setLoading] = useState(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getDocs(articleRef)
            .then(res => {
                const articles = res.docs.map(item => ({
                    id: item.id,
                    ...item.data()
                }));
                const europeArtticles = articles.filter(item => item.tourTime === 'europe')
                setEuropeTours(articles.filter(item => item.tourTime === 'europe'))
                setLoading(false);
            })
            .catch(err => console.log(err))
    }, [])


    return (
        <div>
            <UnderConstruction />
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="featured-tours-container">
                {
                    europeTours.map(item => {
                        return <Link style={{ textDecoration: 'none' }} to={`/tourDetails/${item.id}`} key={item.id}> <TourCards tourImg={item.cardImage} key={item.id} tourLocation={item.destination} /></Link>
                    })
                }
            </div >
            <div className="border-margin">
                <div className="border"></div>
            </div>
        </div>

    )
}

export default EuropeTours