import React, { useState } from 'react'
// import {useEffect} from 'react'
import '../css/bookingform.css'
import { MdOutlineAddHomeWork } from 'react-icons/md'
import TravelersNames from './TravelersNames'
import ContactInfo from './ContactInfo'
import TourInquiry from './TourInquiry'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../config/firebaseConfig'

function BookingForm({ openBookingForm, setOpenBookingForm }) {
    const [formData, setFormData] = useState({
        firstName1: "",
        lastName1: "",
        firstName2: "",
        lastName2: "",
        firstName3: "",
        lastName3: "",
        firstName4: "",
        lastName4: "",
        cellNumber: "",
        homeNumber: "",
        email: "",
        homeAddress: "",
        tour: "",
        departure: "",
        remarks: "",
        accomodation: "",
        contactBy: ""
    }, [])

    const createBooking = (e) => {
        e.preventDefault();
        const articleRef = collection(db, "bookings")
        addDoc(articleRef, {
            firstName1: formData.firstName1,
            lastName1: formData.lastName1,
            firstName2: formData.firstName2,
            lastName2: formData.lastName2,
            firstName3: formData.firstName3,
            lastName3: formData.lastName3,
            firstName4: formData.firstName4,
            lastName4: formData.lastName4,
            cellNumber: formData.cellNumber,
            homeNumber: formData.homeNumber,
            email: formData.email,
            homeAddress: formData.homeAddress,
            tour: formData.tour,
            departure: formData.departure,
            remarks: formData.remarks,
            accomodation: formData.accomodation,
            contactBy: formData.contactBy

        })
            .then(res => {
                alert('Booking Form Submitted')
            })
            .catch(err => {
                console.log(err)
            })
    }

    // useEffect(() => {
    //     console.log(formData)
    // }, [formData])

    return (
        <form className="booking-form-container" onSubmit={createBooking}>
            <div className="booking-form-card">
                <div className="header">
                    <p className="card-title"> Booking Form</p>
                    <MdOutlineAddHomeWork className="home-icon" />
                </div>
                <TravelersNames formData={formData} setFormData={setFormData} />
                <ContactInfo formData={formData} setFormData={setFormData} />
                <TourInquiry formData={formData} setFormData={setFormData} />
                <div className="booking-btns-container">
                    <button className="booking-btns" type="button" onClick={() => setOpenBookingForm(false)}>Close</button>
                    <button className="booking-btns" type="submit" onClick={() => setOpenBookingForm(false)}>Submit</button>
                    <button className="booking-btns" type="reset">Reset</button>
                </div>
            </div>
        </form >

    )
}


export default BookingForm