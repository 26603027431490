import './App.css';
import React, { useState } from 'react'
import Homepage from './components/Homepage'
import Header from './components/Header'
import Footer from './components/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import FeaturedTours from './components/FeaturedTours';
import TourDetails from './components/TourDetails';
import PastTours from './components/PastTours';
import HostedTours from './components/HostedTours';
import CustomTours from './components/CustomTours';
import ReligousTours from './components/ReligousTours';
import EuropeTours from './components/EuropeTours';
import DashBoard from './components/DashBoard';
import TourEdit from './components/TourEdit';


function App() {

  const [openBookingForm, setOpenBookingForm] = useState(false);


  return (
    <BrowserRouter>
      <Header openBookingForm={openBookingForm} setOpenBookingForm={setOpenBookingForm}></Header>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/featuredTours" element={<FeaturedTours />} />
        <Route path="/tourDetails/:tourId" element={<TourDetails setOpenBookingForm={setOpenBookingForm} />} />
        <Route path="/pastTours" element={<PastTours />} />
        <Route path="/hostedTours" element={<HostedTours />} />
        <Route path="/customTours" element={<CustomTours />} />
        <Route path="/religiousTours" element={<ReligousTours />} />
        <Route path="/europeTours" element={<EuropeTours />} />
        <Route path="/dashBoard" element={<DashBoard />} />
        <Route path="/tourEdit/:tourId" element={<TourEdit setOpenBookingForm={setOpenBookingForm} />} />
      </Routes>
      <Footer></Footer>
    </BrowserRouter>


  );
}

export default App;