import React from 'react'
import '../css/bookingform.css'

function ContactInfo({ formData, setFormData }) {
    // const [cellNumber, setCellNumber] = useState('')
    // const [homeNumber, setHomeNumber] = useState('')
    // const [email, setEmail] = useState('')


    return (
        <div className="contact-info-container">
            <p>Contact Info:</p>
            <div className="phone-nos">
                <input
                    type="phone"
                    placeholder='Cell No'
                    onChange={(e) => setFormData({ ...formData, cellNumber: e.target.value })}
                />
                <input
                    type="phone"
                    placeholder='Home No'
                    onChange={(e) => setFormData({ ...formData, homeNumber: e.target.value })}
                />
            </div>
            <div className="email-address-container">
                <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
                <input
                    type="text"
                    placeholder='Address'
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                />
            </div>

        </div>
    )
}

export default ContactInfo