import React from 'react'
import '../css/bookingform.css'

function TravelersNames({ formData, setFormData }) {

    // const [firstName, setFirstName] = useState('')
    // const [lastName, setLastName] = useState('')

    return (
        <div className="travelers-name-container">
            <p>Travelers Names:</p>
            <div className="travelers-name">
                <input
                    type="name"
                    placeholder="Last Name"
                    onChange={(e) => setFormData({ ...formData, lastName1: e.target.value })}
                />
                <input
                    type="name"
                    placeholder="First Name"
                    onChange={(e) => setFormData({ ...formData, firstName1: e.target.value })}
                />
            </div>
            <div className="travelers-name">

                <input
                    type="name"
                    placeholder="Last Name"
                    onChange={(e) => setFormData({ ...formData, lastName2: e.target.value })}
                />
                <input
                    type="name"
                    placeholder="First Name"
                    onChange={(e) => setFormData({ ...formData, firstName2: e.target.value })}
                />
            </div>
            <div className="travelers-name">

                <input
                    type="name"
                    placeholder="Last Name"
                    onChange={(e) => setFormData({ ...formData, lastName3: e.target.value })}
                />
                <input
                    type="name"
                    placeholder="First Name"
                    onChange={(e) => setFormData({ ...formData, firstName3: e.target.value })}
                />
            </div>
            <div className="travelers-name">

                <input
                    type="name"
                    placeholder="Last Name"
                    onChange={(e) => setFormData({ ...formData, lastName4: e.target.value })}
                />
                <input
                    type="name"
                    placeholder="First Name"
                    onChange={(e) => setFormData({ ...formData, firstName4: e.target.value })}
                />
            </div>
        </div>
    )
}

export default TravelersNames