import React from 'react'
import '../css/underconstruction.css'

function UnderConstruction() {
    return (
        <div className="under-construction-container">
            <div className="under-construction-text">This Page is Under Construction</div>
            <img className="under-construction-img" src="/assets/underConstruction.jpg" alt="underConstruction" />
        </div>
    )
}

export default UnderConstruction