import React, { useEffect, useState } from 'react'
import '../css/footer.css'
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';
import { Link } from 'react-router-dom'

function Promos() {

    const articleRef = collection(db, "tourPackages")
    const [featuredTours, setFeaturedTours] = useState([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getDocs(articleRef)
            .then(res => {
                const articles = res.docs.map(item => ({
                    id: item.id,
                    ...item.data()
                }))
                setFeaturedTours(articles.filter(item => item.tourTime === 'featured'))
            })
            .catch(err => console.log(err))
    }, [])



    return (
        <div className="promo-sections-container" >
            {
                featuredTours?.map(item => {
                    return <div key={item.id} className="promo-sections"><Link style={{ textDecoration: 'none', color: 'black' }} to={`/tourDetails/${item.id}`} key={item.id}>{item.destination}</Link></div>
                })
            }
        </div>
    )
}

export default Promos

