import React from 'react'
import '../css/tourcards.css'

function TourCards({ tourImg, tourLocation }) {
    //parent is FeaturedTours.js//


    return (
        <div>

            <div className="tour-cards">
                <img className="tour-img" alt="card-img" src={`${tourImg}`}></img>
                <div>{tourLocation}</div>
            </div>

        </div >

    )
}

export default TourCards