import { React, useState } from 'react'
import '../css/header.css'
import { BsFillTelephoneFill, BsEnvelopeAt } from 'react-icons/bs'
import NavBar from './NavBar'
import { Link } from 'react-router-dom'


function Header({ openBookingForm, setOpenBookingForm }) {

    const handleLogoClick = (event) => {
        if (event.altKey) {
            window.location.href = '/dashboard';
        }
    };


    return (
        <div className="header-container">
            <div className="top-header">
                <div className="header-logo">
                    <Link to="/" onClick={handleLogoClick}><img className="logo" alt="airplusLogo" src="/assets/airplusLogo2.jpg"></img></Link>
                    <div className="logo-text">The Home For Religious and Customized Travel</div>
                </div>
                <div className="header-contact">
                    <div className="sub-contact">
                        <BsFillTelephoneFill className="phone-icon" />
                        <div>
                            <div>562-863-0624</div>
                            <div>866-664-7611</div>
                        </div>
                    </div>
                    <div className="sub-contact">
                        <BsEnvelopeAt className="envelope-icon" />
                        <div>info@airplustravel.net </div>
                    </div>
                </div>
            </div>
            <NavBar openBookingForm={openBookingForm} setOpenBookingForm={setOpenBookingForm} />
        </div>

    )
}

export default Header