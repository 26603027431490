import React from 'react'
import '../css/bookingform.css'

function TourInquiry({ formData, setFormData }) {


    return (
        <div className="tour-inquiry-container">
            <input
                type="text"
                onChange={(e) => setFormData({ ...formData, tour: e.target.value })}
                placeholder="Tour Title"
            />
            <input
                type="text"
                placeholder='Departure City'
                onChange={(e) => setFormData({ ...formData, departure: e.target.value })}
            />
            <div className="tour-inquiry-text">Accomodation:</div>
            <div className="bed-container">
                <p>Single</p>
                <input type="checkbox" name="Single" value="one person"
                    onChange={(e) => setFormData({ ...formData, accomodation: e.target.value })} />
                <p>Twin</p>
                <input type="checkbox" name="Twin" value="two people"
                    onChange={(e) => setFormData({ ...formData, accomodation: e.target.value })} />
                <p>Triple</p>
                <input type="checkbox" name="Triple" value="three people"
                    onChange={(e) => setFormData({ ...formData, accomodation: e.target.value })} />
            </div>
            <div>
                <p className="tour-inquiry-text">Remarks: </p>
                <input
                    type="text"
                    name="Remarks"
                    onChange={(e) => setFormData({ ...formData, remarks: e.target.value })} />
            </div>
            {/* <div>
                <p className="tour-inquiry-text">How do you want us to contact you:</p>
                <div className="bed-container">
                    <p>Call</p>
                    <input type="checkbox" name="phone" value="phone"
                        onChange={(e) => setFormData({ ...formData, contactBy: e.target.value })} />
                    <p>Email</p>
                    <input type="checkbox" name="Email" value="email"
                        onChange={(e) => setFormData({ ...formData, contactBy: e.target.value })} />
                    <p>Text</p>
                    <input type="checkbox" name="message" value="message"
                        onChange={(e) => setFormData({ ...formData, contactBy: e.target.value })} />
                </div>
            </div> */}
        </div>
    )
}

export default TourInquiry