import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../css/navbar.css'
import { FaBars, FaTimes } from 'react-icons/fa'
import BookingForm from './BookingForm'

function MobileNavigation({ openBookingForm, setOpenBookingForm }) {

    const [openNav, setOpenNav] = useState(false)

    return (
        <div>
            <div className={` ${openNav ? 'mobile-nav' : 'nav'}`} onClick={() => setOpenNav(false)}>
                <Link className="link" to="/">Home</Link>
                <Link className="link " to="/featuredTours/">Featured Tours</Link>
                <Link className="link" to="/customTours">Custom Tours</Link>
                <Link className="link" to="/hostedTours">Hosted Tours</Link>
                <Link className="link" to="/pastTours">Past Tours</Link>
                <button className="booking-btn" onClick={() => setOpenBookingForm(true)}>Booking Form</button>
                <FaTimes className="menu-icon" onClick={() => setOpenNav(false)} />
            </div >
            <FaBars className={`menu-icon ${!openNav ? '' : 'menu-close'}`} onClick={() => setOpenNav(true)} />
            <div className={`${openBookingForm ? 'show-form' : 'close-form'}`}>
                <div className="form-overlay"></div>
                <BookingForm setOpenBookingForm={setOpenBookingForm} />
            </div>

        </div>
    )
}

export default MobileNavigation

