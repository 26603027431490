import React from 'react'
import '../css/footer.css'
import Promos from './Promos'
import Social from './Social'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div>
            <div className="promo-container">
                <Link to="/featuredTours" className="featured-tours-link">Featured Tours</Link>
                <Promos />
            </div>
            <Social />
        </div>

    )
}

export default Footer