import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../css/footer.css'

function Social() {

    const [showAboutUs, setShowAboutUs] = useState(false)
    const [showFaq, setShowFaq] = useState(false)


    return (
        <div className="social-container">
            <div className="company-info">
                <div onClick={() => setShowAboutUs(!showAboutUs)}>
                    <button className="about-btn" onClick={() => setShowAboutUs(true)}>About Us</button>
                    <div className={`${showAboutUs ? "about-content-container" : "closed"}`}>
                        <div className="about-content" >
                            <p>
                                Welcome to Air Plus Travel, where we specialize in crafting transformative journeys that blend the sacred with the personal. At Air Plus Travel, we understand the profound significance of religious travel, and our mission is to curate bespoke experiences that cater to your spiritual aspirations.
                            </p>
                            <br></br>
                            <p>With a passion for customization, we go beyond traditional travel offerings, ensuring each pilgrimage or spiritual adventure is meticulously tailored to meet your unique preferences and requirements. Our dedicated team combines expertise in religious destinations with a commitment to personalized service, striving to turn your travel dreams into profound, soul-enriching realities.
                            </p>
                            <br></br>
                            <p>
                                Whether you're embarking on a pilgrimage to sacred sites, participating in religious festivals, or seeking contemplative retreats, Air Plus Travel is your trusted partner in creating meaningful connections with the divine. Join us on a journey that transcends ordinary travel, and let the spirit of exploration guide you to a deeper understanding of faith and culture.
                            </p>
                            <br></br>
                            <p>
                                Experience the difference of travel with purpose – choose Air Plus Travel for religious tours and customized travel that resonate with your soul.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="registration-info">
                    <p>Registration</p>
                    <p>California Seller of Travel:</p>
                    <p>2061414-40</p>
                </div>
                <div onClick={() => setShowFaq(!showFaq)}>


                    <button className="faq-btn" onClick={() => setShowFaq(true)}>FAQ</button>
                    <div className={`${showFaq ? "faq-content-container" : "closed"}`}>
                        <div className="faq-content">
                            <p>Travel Frequently Asked Questions</p>
                            <br></br>
                            <ol>
                                <li>
                                    Q: What documents do I need to travel internationally?
                                    A: It depends on your destination, but typically you'll need a valid passport. Some countries also require a visa. Check the entry requirements for your specific destination well in advance of your trip.
                                </li>
                                <br></br>
                                <li>
                                    Q: How far in advance should I book my flights?
                                    A: It's generally recommended to book flights at least a few months in advance for the best prices, especially for popular destinations or during peak travel seasons. However, flexibility with travel dates can also lead to finding good deals closer to your departure date.
                                </li>
                                <br></br>
                                <li>
                                    Q: What are the best ways to save money on accommodations?
                                    A: Consider options like staying in budget hotels, hostels, or guesthouses. Booking accommodations well in advance can also result in lower prices. Additionally, using travel rewards programs or booking through websites that offer discounts can help save money.
                                </li>
                                <br></br>
                                <li>
                                    Q: What should I pack for my trip?
                                    A: Pack essentials like clothing suitable for the destination's climate, toiletries, medications, and any necessary travel documents. It's also a good idea to bring a travel adapter, portable charger, and any specific items you'll need for activities or excursions.
                                </li>
                                <br></br>
                                <li>
                                    Q: Is travel insurance necessary?
                                    A: While not always mandatory, travel insurance can provide valuable coverage for unexpected events such as trip cancellations, medical emergencies, or lost luggage. It's worth considering, especially for international travel or trips involving expensive bookings.
                                </li>
                                <br></br>
                                <li>
                                    Q: How do I navigate public transportation in a new city?
                                    A: Research the public transportation options available in advance, such as buses, trains, or subways. Maps and apps can help you plan routes and understand fare systems. If unsure, don't hesitate to ask locals or transportation staff for assistance.
                                </li>
                                <br></br>
                                <li>
                                    Q: What are some must-see attractions in [destination]?
                                    A: This varies depending on the destination, but popular attractions often include landmarks, museums, historical sites, and natural wonders. Researching travel guides or asking locals for recommendations can help you prioritize your sightseeing activities.
                                </li>
                                <br></br>
                                <li>
                                    Q: How can I stay safe while traveling solo?
                                    A: Stay vigilant and aware of your surroundings, especially in unfamiliar areas. Avoid risky situations and trust your instincts. It's also a good idea to share your itinerary with someone you trust and keep emergency contact information handy.
                                </li>
                                <br></br>
                                <li>
                                    Q: What are the local customs and etiquette I should be aware of?
                                    A: Researching the cultural norms of your destination beforehand can help you avoid unintentional offenses. Pay attention to things like greetings, dress codes, tipping customs, and appropriate behavior in public spaces.
                                </li>
                                <br></br>
                                <li>
                                    Q: How do I deal with jet lag effectively?
                                    A: Adjust your sleep schedule gradually before your trip, stay hydrated during the flight, and try to get some sunlight and exercise upon arrival to help reset your internal clock. Some travelers find over-the-counter or prescription sleep aids helpful, but consult with a healthcare professional before using them.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="social-icons">
                <p>Follow Us:</p>
                <Link className="social-grp" to="https://www.facebook.com/airplustravel.net/" target="_blank"><img className="icon-size" src="/assets/facebookIcon.png" alt="facebook"></img><p>Facebook</p></Link>
                <Link className="social-grp" to="https://www.instagram.com/peritojr.emilio/" target="_blank"><img className="icon-size" src="/assets/instagramIcon2.png" alt="instagram"></img> <p>Instagram</p></Link>
                <Link className="social-grp" to="https://twitter.com/airplustravel1" target="_blank"><img className="icon-size" src="/assets/x.png" alt="twitter"></img><p>Twitter</p></Link>
                <Link className="social-grp" to="https://www.pinterest.com/airplustravel1/" target="_blank"><img className="icon-size" src="/assets/pinterestIcon.png" alt="pinterest"></img><p>Pinterest</p></Link>
                <Link className="social-grp" to="https://www.youtube.com/channel/UCXRLGdGfU2fQ33HlhnQjnlQ?view_as=subscriberEt12yK9I9wzz9v95k35RAM?e=download" target="_blank"><img className="icon-size" src="/assets/youtubeIcon2.jpeg" alt="youtube"></img><p>Youtube</p></Link>
            </div>
        </div >
    )
}

export default Social