import React from 'react'
import { Link } from 'react-router-dom'
import '../css/hostedtours.css'

function HostedTours() {


    return (
        <div>

            <div className="hosted-tours-container">

                <div className="religious-tours-card">
                    <Link to="/religiousTours" style={{ textDecoration: "none" }} className="tour-poster-container">
                        <div className="tour-poster">
                            <img className="religious-travel-picture" alt="religious-travel" src="https://firebasestorage.googleapis.com/v0/b/airplustrv.appspot.com/o/religiousTravelerResize.jpg?alt=media&token=e6d2f2e9-dd32-4da2-9f44-9f1288eebf16"></img>
                        </div>
                        <div className="text">
                            Have you ever wondered what it feels like to walk in the very places where saints once tread, or to pray in ancient sites where miracles unfolded? Imagine embarking on a journey not just across the world, but deep into the heart of your faith. What if you could see the scriptures come to life before your eyes, offering you a tangible connection to the divine? Our spiritual pilgrimage tours invite you to explore sacred grounds, immerse yourself in reflection, and join a community of seekers, all while deepening your relationship with God and Jesus Christ. This could be the path to the spiritual renewal and transformation you've been searching for.
                        </div>

                    </Link>
                </div>
                <div className="europe-tours-card">
                    <Link to="/europeTours" style={{ textDecoration: "none" }}>
                        <div className="tour-poster">
                            <img className="europe-travel-picture" alt="europe-travel" src="https://firebasestorage.googleapis.com/v0/b/airplustrv.appspot.com/o/europeTravel.jpg?alt=media&token=4f4f0793-d4aa-4cb5-9732-6cf43031639c"></img>
                        </div>
                        <div className="text">
                            Get ready for the adventure of a lifetime in Europe! Picture yourself twirling pasta in Rome, chasing the aroma of freshly baked baguettes in Paris, and toasting with frothy beer at Munich's Oktoberfest. Europe is your playground of architectural wonders, from the Colosseum to Gaudi's masterpieces. Dive into flea markets for hidden gems or let decadent Belgian chocolates melt in your mouth. Join the jubilant crowds at vibrant festivals and share laughter with locals. Europe doesn't just call; it sings and dances, promising joy in every discovery. Come find your moment of pure "ah-ha" joy in Europe's embrace!
                        </div>
                    </Link>
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
        </div>
    )
}

export default HostedTours