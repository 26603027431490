import React, { useEffect, useState } from 'react';
import '../css/tourdetails.css';
import { db, storage } from '../config/firebaseConfig';
import { getDoc, doc } from 'firebase/firestore';
import { useParams } from 'react-router-dom'
import { ref, getDownloadURL } from 'firebase/storage'
import BookingForm from './BookingForm';




function TourDetails({ openBookingForm, setOpenBookingForm }) {

    //from TourCards//


    const { tourId } = useParams()
    const [tourPage, setTourPage] = useState([])
    const [tourItinerary, setTourItinerary] = useState([])
    const [tourSummary, setTourSummary] = useState([])
    const [openItinerary, setOpenItinerary] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState([])

    useEffect(() => {
        const docRef = doc(db, "tourPackages", tourId)
        getDoc(docRef)
            .then(res => {
                setTourPage(res.data())
                setTourItinerary(res.data().itinerary)
                setTourSummary(res.data().tourSummary)
            })
            .catch(err => console.log(err))
    }, [tourId])

    const imageRef = ref(storage, 'religiousTravelerResize.jpg')
    getDownloadURL(imageRef)
        .then(res => {
            console.log(res)
        })


    return (
        <div>
            <div className="banner-container">
                <img className="banner-img" alt="img-banner" src={`${tourPage.imageBanner}`}></img>
                <p className="banner-text"></p>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="tour-details-container">
                <div className="lh-container">
                    <div className="tour-summary-container">
                        <div className="tour-summary">
                            {
                                tourSummary?.map(item => {
                                    return <p key={item.id}>{item}</p>
                                })
                            }
                        </div>
                        <div className="tour-summary-btn-container"><button className="tour-summary-btn">Contact Us For Other City Departure</button></div>
                    </div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <div className="intro-container">
                        <div className="intro-btns-container">
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('highlights') }}>Highlights</button>
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('inclusions') }}>Inclusions</button>
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('hotels') }}>Hotels</button>
                            <button className="intro-btns" onClick={() => { setShowModal(true); setModalContent('price') }}>Price</button>
                            <button className="intro-btns" onClick={() => setOpenBookingForm(true)}>Booking Form</button>
                            <div className={`${openBookingForm ? 'show-form' : 'close-form'}`}>
                                <div className="form-overlay"></div>
                                <BookingForm setOpenBookingForm={setOpenBookingForm} />
                            </div>
                        </div>
                        {showModal && (
                            <div className="modal open">
                                <div className="modal-text">
                                    <ul>
                                        {tourPage[modalContent]?.map((item, index) => (
                                            <p key={index}>{item}</p>
                                        ))}
                                    </ul>
                                </div>
                                <button className="intro-btns" onClick={() => setShowModal(false)}>Close</button>
                            </div>
                        )}
                        <div className="border-margin">
                            <div className="border"></div>
                        </div>
                        <div className="intro-content-container">
                            <p>Introduction</p>
                            <p>
                                {tourPage.introContent}
                            </p>
                        </div>

                    </div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <div className="itinerary-container">
                        <p onClick={() => setOpenItinerary(!openItinerary)}>Itinerary</p>
                        <div className={`itinerary-day ${openItinerary ? '' : 'close'}`}>
                            {
                                tourItinerary?.map(item => {
                                    return <p key={item.id}>{item}</p>
                                })
                            }
                        </div>
                    </div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <img className="tour-detail-imgs" src={`${tourPage.image1}`} alt="img1"></img>
                    <div className="tour-img-text">{tourPage.imageContent1}</div>
                </div>
                <div className="rh-container">
                    <img className="tour-detail-imgs" src={`${tourPage.image2}`} alt="img2"></img>
                    <div className="tour-img-text">
                        {tourPage.imageContent2}
                    </div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <img className="tour-detail-imgs" src={`${tourPage.image3}`} alt="img3"></img>
                    <div className="tour-img-text">{tourPage.imageContent3}</div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                    <img className="tour-detail-imgs" src={`${tourPage.image4}`} alt="img4"></img>
                    <div className="tour-img-text">{tourPage.imageContent4}</div>
                    <div className="border-margin">
                        <div className="border"></div>
                    </div>
                </div>
            </div>
            <div className="border-margin">
                <div className="border"></div>
            </div>
        </div >
    )
}

export default TourDetails