import React from 'react'
import '../css/homepage.css'
import Banner from './Banner'
import { Link } from 'react-router-dom'



function Homepage() {

    const euroPic = 'assets/louvreMuseum.jpg'
    const religiousPic = '/assets/israelLandscape.webp'
    const customPic = '/assets/peopleHearts.jpeg'

    return (
        <div>
            <Banner />
            <div className="border-margin">
                <div className="border"></div>
            </div>
            <div className="homepage-container">
                <Link to="/religiousTours" style={{ textDecoration: 'none', color: '#000000' }}>
                    <div className="tour-container">
                        <img className="tour-pic" src={religiousPic} alt="israel-landscape"></img>
                        <div className="tour-text">
                            <div className="tour-content">
                                <p>RELIGIOUS TOURS</p>
                                <br></br>
                                <p>Discover the Holy Land, where faith and wonder intertwine, a timeless destination that will forever shine. With ancient tales echoing through sacred lands, where history and spirituality hold hands.</p>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="/europeTours" style={{ textDecoration: 'none', color: '#000000' }}>
                    <div className="tour-container">
                        <div className="tour-text">
                            <div className="tour-content">
                                <p>EUROPE TOURS</p>
                                <br></br>
                                <p>Explore Rome's ancient allure, Venice's romantic canals, and Paris's iconic Louvre Pyramid. Immerse yourself in art, history, and culture. Your unforgettable journey through Europe begins here!</p>
                            </div>
                        </div>
                        <img className="tour-pic" src={euroPic} alt="louvreMuseum"></img>
                    </div>
                </Link>
                <Link to="/customTours" style={{ textDecoration: 'none', color: '#000000' }}>
                    <div className="tour-container">
                        <img className="tour-pic" src={customPic} alt="peopleHearts"></img>
                        <div className="tour-text">
                            <div className="tour-content">
                                <p>CUSTOM-BUILT TOURS</p>
                                <br></br>
                                <p>Custom-built tours offer a personalized and tailored travel experience based on your specific preferences and interests.  If you have plans for your group, ask us about it!</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div >
            <div className="border-margin">
                <div className="border"></div>
            </div>
        </div >
    )
}

export default Homepage